// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-extrapet-index-tsx": () => import("./../../../src/pages/extrapet/index.tsx" /* webpackChunkName: "component---src-pages-extrapet-index-tsx" */),
  "component---src-pages-hospet-index-tsx": () => import("./../../../src/pages/hospet/index.tsx" /* webpackChunkName: "component---src-pages-hospet-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-performancepet-index-tsx": () => import("./../../../src/pages/performancepet/index.tsx" /* webpackChunkName: "component---src-pages-performancepet-index-tsx" */),
  "component---src-pages-popularpet-index-tsx": () => import("./../../../src/pages/popularpet/index.tsx" /* webpackChunkName: "component---src-pages-popularpet-index-tsx" */)
}

